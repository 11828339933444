.hero {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#009bdd+10,004379+90 */
  background: rgb(0,155,221); /* Old browsers */
  background: -moz-linear-gradient(top,  rgba(0,155,221,1) 10%, rgba(0,67,121,1) 90%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(0,155,221,1) 10%,rgba(0,67,121,1) 90%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(0,155,221,1) 10%,rgba(0,67,121,1) 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009bdd', endColorstr='#004379',GradientType=0 ); /* IE6-9 */

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  max-width: 100%;
  /* Reverse Column Order */
  -webkit-flex-flow: column-reverse;
  flex-flow: column-reverse;
  @include size(100%, auto);	
	 position: relative;
  margin: 0 auto;
	 @include tablet {
	 }
  @include desktop {
    -webkit-flex-flow: initial;
    flex-flow: initial;
		  @include size(100%, 600px);
	 }
  @include md {
	 }

  &-text {
		padding: 0;	
		width: 100%;
		z-index: 200;
  opacity: 1;
  margin: 0 auto;
  position: absolute;
  width: 100%;
		@include tablet {
		}		
		@include desktop {
			 padding: 0 2rem;
			 bottom: -2rem;		
		}	
		@include ie {
			width: auto !important;	
		}	
		@include edge {
			width: auto !important;	
		}
  &.align-bottom {
    top: initial;
    bottom: initial;
    @include tablet {
      bottom: 2.5rem;
    }
  }
	}
	&-pre-headline {
		background: none;
		color: $blue;
		font-family: $nav;
		display: table;
		margin: 0 auto;
		text-transform: uppercase;
		padding: 0;
		margin-bottom: 0.1rem;
		opacity: 1;
		position: relative;
		z-index: 200;
      @extend %headline5;
      &:hover {
         color: $white;
      }
      &:focus {
         color: $white;
         box-shadow: 0 0 0 4px $white;
      } 
	}
	&-headline {
		color: $white;
		font-family: $headline;
		line-height: 1;
		opacity: 1;
  position: relative;
  @extend %headline2;
  font-weight: 400;
  display: inline-block;
  @include tablet {
    border: 1px $white solid;
    padding: 0.75rem 2rem;
  }
  &-link {
     color: $white;
  }
	}
 &.no-photo {
  @include size(100%, 10rem);
  position: relative;
  display: block;
  padding: 2rem 0 .5rem;
 	@include tablet {
 		 @include size(100%, 200px);
    padding: 1.5rem 0;
 	}
  @include desktop {
 		 @include size(100%, 220px);
    padding: 1.5rem 0;
 	}
  @include md {
    @include size(100%, 240px);
    padding: 2rem 0;
 	}

 }
 &.no-photo .hero-text {
    top: initial;
    bottom: 0;
    position: absolute;
    @include tablet {
      bottom: .5rem;
    }
    @include desktop {
      bottom: 1rem;
    }
    @include md {
      bottom: 2rem;
    }
 }
 &.no-photo .hero-headline::after {
    display: none;
 }
	&-blurb {
		color: $white;
  max-width: $max-width-text;
		font-size: .9rem;
		line-height: 1.2;
		margin: 0 auto;
		width: 90%;
  padding: 0 0 1rem;
  opacity: 1;
		@include tablet {
			font-size: 1.1rem;
		}		
		@include desktop {
			font-size: 1.2rem;
		}		
		p {
			margin-bottom: 0;
		}
	}	

   &-grad {
    mix-blend-mode: color;
   	@include size(100%, 100%);	
  		@include absolute(left 0 top 0);
  		opacity: 1;
  		z-index: 2;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00f0ff+25,00f0ff+75,00f0ff+75&0.4+25,0+75 */
    background: -moz-linear-gradient(-45deg,  rgba(0,240,255,0.4) 25%, rgba(0,240,255,0) 75%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  rgba(0,240,255,0.4) 25%,rgba(0,240,255,0) 75%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  rgba(0,240,255,0.4) 25%,rgba(0,240,255,0) 75%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6600f0ff', endColorstr='#0000f0ff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    @include desktop {
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00f0ff+25,00f0ff+75,00f0ff+75&0.4+25,0+75 */
      background: -moz-linear-gradient(-45deg,  rgba(0,240,255,0.4) 25%, rgba(0,240,255,0) 75%); /* FF3.6-15 */
      background: -webkit-linear-gradient(-45deg,  rgba(0,240,255,0.4) 25%,rgba(0,240,255,0) 75%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(135deg,  rgba(0,240,255,0.4) 25%,rgba(0,240,255,0) 75%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6600f0ff', endColorstr='#0000f0ff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    }
   }
   &-photo {
    filter: grayscale(100);
  		@include size(100%, 100%);	
  		@include absolute(left 0 top 0);
  		opacity: 1;
  		z-index: 1;
  		mix-blend-mode: soft-light;
  		&-pic {
  		  filter: contrast(1.1);
   			@include size(100%);	
   			object-fit: cover;
   			overflow: hidden;	
      &.opacity-70 {
         opacity: .7;
      }
      @include desktop {
   			  @include absolute(left 0 top 0);
      }
    }
		  &-img {
			  @include size(100%);	
			  object-fit: cover;
     opacity: 0;
     transition: $slow;
     transition-delay: 0.25s;
     &.reveal {
       opacity: 1;
     }
		 }				
	}

 & .hero-photo {
   position: relative;
		 @include desktop {
		   position: absolute;
		 }
 }

}

