.section-front {
   
   &-grid {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: flex-start;   
   }
   &-item {
      background: $white;
      padding: 0 1rem 2rem;
      width: 100%;
      margin: 1rem auto;
      transition: $slow;
      opacity: 0;
      transform: translateY(4rem);
      &.reveal {
         opacity: 1.0;
         transform: translateY(0);
      }
      @include tablet {
         max-width: 50%;
      }      
   }
   &-aspect {
      text-decoration: none !important;
      &:hover .section-front-photo-img {
         -moz-transform: scale(1.2);
         -webkit-transform: scale(1.2);
         -o-transform: scale(1.2);
         -ms-transform: scale(1.2);
         transform: scale(1.2);
         opacity: 1.0;
      }
      &:hover .section-front-headline {
         color: $red;
      }
      &:hover .button.more {
         color: $red;
         &::after {
            background: $red; 
         }
      }
   }
   &-text {
      position: relative;
      top: -5rem;
      z-index: 200;
      background: $white;
      padding: 1.5rem;
      margin: 1.5rem 1.5rem -3rem 1.5rem;
      text-align: center;
      -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,.2);
      box-shadow: 0 0 20px 0 rgba(0,0,0,.2);
   }
   &-headline {
      font-family: $headline2 !important;
      @extend %headline3;
      letter-spacing: -.5px;
      margin-bottom: .25rem;
      transition: $slow;
   }
   &-description {
      display: block;
      color: $black !important;
      font-size: 80%;
      line-height: 1.4;
      margin-bottom: .25rem;
   }
   &-photo {
      z-index: 100;
      position: relative;
      width: 100%;
      height: 300px;
      background: $darkgray;
      border-bottom: 8px yellow solid;
      overflow: hidden;
      &-img {
         width: 100%;
         height: 100%;
         background-size: cover;
         background-repeat: no-repeat;
         background-position: center center;
         opacity: .5;
         transition: $veryslow;
      }
   }
   &-logo {
     z-index: 300;
     position: relative;
     top: -75px;
     margin-bottom: -65px;
     &-img {
      width: 100px;
      height: 100px;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius:100%;
      margin: 0 auto;
      -webkit-box-shadow: 0 0 20px 0 rgba(0,0,0,.2);
      box-shadow: 0 0 20px 0 rgba(0,0,0,.2);
     }
   }
}
