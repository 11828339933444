.news-feed {
  text-align: center;
  @include tablet {
    padding: 0;
    text-align: left;
  }
}

.news-list {
  margin: 0 auto 2rem;
  display: block;
  padding: 1rem;
  @include tablet {
    margin: 2rem auto;
    padding: 0;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat( auto-fit, minmax(33.33%, 1fr) );
    grid-template-columns: repeat(2, 1fr);
  }
  @include desktop {
    grid-template-columns: repeat(3, 1fr);
  }
  &-item {
    width: 100%;
    margin: 0 0 1rem 0;
    transition: $slow;
    opacity: 0;
    transform: translateY(4rem);
    background: $darkblue;
    border-radius: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
    -webkit-box-shadow:inset 0 0 35px 0 rgba(0,0,0,.35);
    box-shadow:inset 0 0 35px 0 rgba(0,0,0,.35);
    aspect-ratio: auto 4 / 3;
    &.reveal {
      opacity: 1.0;
      transform: translateY(0);
    }

    @include tablet {
      margin: 0;
    }
    @include desktop {
    }
    &:hover {
      background: $blue;
    }
    &-link {
      text-decoration: none !important;
      @include size(100%, 100%);	
      position: relative;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex !important;
    }
    &-aspect {
   
      position: relative;
      z-index: 100;
      padding: 1rem;    
     
    }
    &-date {
      font-size: .9rem;
      font-weight: 700;
      color: $white;
      display: block;
      margin: 0 0 .5rem 0;
    }
    &-headline {
      font-family: $headline2;
      color: $white;
      margin-bottom: 0;
      padding-bottom: .5rem;
      font-size: 1.65rem;
      font-weight: 400;
      text-transform: none;
      text-decoration: none;
      line-height: 1;

    }
    &-photo {
 		   @include size(100%, 100%);	
 		   @include absolute(left 0 top 0);
      overflow: hidden;
      transition: $slow;
      z-index: 1;

    		&-pic {
        @include tablet {
       			@include size(100%);	
       			@include absolute(left 0 top 0);
       			object-fit: cover;
       			overflow: hidden;	
          transition: $slow;
          filter: contrast(1.65);
        }
      }
     	&-img {
     			@include size(100%);	
     			object-fit: cover;
        transition: $veryslow;
        display: block;
        opacity: .45;
    		}				
    }
  }
}


