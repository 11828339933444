.expander { 
  margin: 0 0 2rem; 
  &-header { 
    cursor: pointer;
    padding: 1rem;
    margin-bottom: 2px;
    display: block;
    background: $lightgray;
    transition: $slow;
    & h3 {
      font-family: $body;
      font-size: 1.2rem;
      color: $darkgray;
      line-height: 1;
      text-transform: none;
      margin: 0;
      padding: 0 2rem 0 0;
      position: relative;
      padding-bottom: 0 !important; 
      transition: $slow;
      &:after {
        background: $red; 
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        content:"+";
        -moz-border-radius:    100%;
        -webkit-border-radius: 100%;
        border-radius:         100%;    
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;
        transition: $slow;
        speak: none;
        color: $white;
        font-size: 1.5rem;
        line-height: .85;
        font-weight: 700;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; 
      }
    }
    &:hover { 
      background: $red; 
      & h3 {
        color: $white !important;
      }
    }
    &:active {
      background: $gray;
    }
    &.selected { 
      background: $gray; 
    }
    & p { 
      margin-bottom: 0; 
    }
  }
  &-body { 
    background: $lightestgray;
    font-size: 1rem;
    padding: 2rem; 
    & p:last-child { 
      margin-bottom: 0; 
    }    
  }
}
  
.expander-header:hover h3:after {  background: $red !important; }
.expander-header:active h3:after {  background: #gray;   }
.expander-header.selected h3:after { background: #gray;  content:"-"; }
