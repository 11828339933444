.folder-grid {
   margin: 2em 0;	
   &-item {
      display: block;
      margin: 0 0 .25rem;
      transition: $slow;
      opacity: 0;
      transform: translateY(4rem);
      &.reveal {
         opacity: 1.0;
         transform: translateY(0);
      }
      &:last-child {
         margin: 0;
      }
   }
   &-aspect {
      display: block !important;
      padding: .75em 1em;
      position: relative;
      top: -.5em;
      left: -.5em;
      text-decoration: none !important;
      background: $lightestgray !important;
      &:hover {
         background: $lightgray !important;
      }
      &:hover .folder-grid-headline {
         color: $red !important;
      }
      &::before {
         content: "";
         width: 8px;
         height: 100%;
         position: absolute;
         top: 0;
         left: 0;
         z-index: 0;
         background: $red; 
      }	
   }
   &-headline {
      display: block;
      font-family: $headline2 !important;
      color: $red !important;
      margin-bottom: 0 !important;
      font-size: 1.8rem !important;
   }
   &-description {
      display: block;
      color: $text;
      font-size: 1rem;
   }
   &.horizontal {
      margin: 0 0 2em 0;
      .folder-grid {
         &-item { 
            display: inline-block;
            margin: 0 .5rem;
            opacity: 1.0;
            transform: translateY(0);
            &::after {
               content: "";
               width: 2px;
               height: 100%;
               top: .15rem;
               right: -.75rem;
               background: $gray;
               position: absolute;
               z-index: -10;
            }
            &:first-child {
               margin: 0 .5rem 0 0;
            }
            &:last-child {
               margin: 0 0 0 .5rem;
               &::after {
                  display: none;
               }
            }
         }
         &-aspect {
            display: inline-block !important;
            padding: 0;
            position: relative;
            top: initial;
            left: initial;
            text-transform: uppercase;
            text-decoration: none !important;
            background: $clear !important;
            &::before {
               display: none;
            }   
            &:hover {
               background: $clear !important;
               &::after {
                  content: "";
                  width: calc(100% + .5rem);
                  height: 4px;
                  position: absolute;
                  bottom: -.25rem;
                  left: -.25rem;
                  z-index: -1;
                  background: $red; 
               }
            }
            &:hover .folder-grid-headline {
               color: $black !important;
            } 
         }
         &-headline {
            display: inline-block;
            font-family: $nav !important;
            color: $darkgray !important;
            margin-bottom: 0 !important;
            font-size: 1.0rem !important;
         }
         &-description {
            display: none;
         }
      }
   }
}

.grid-layer-col.partners {
  display: grid;
  align-content: flex-start;
  grid-template-columns: repeat(3, 1fr);
  @include tablet {
    grid-template-columns: repeat(5, 1fr);
  }
  @include desktop {
    grid-template-columns: repeat(7, 1fr);
  }
}
.homepage-partner {
  padding: .5rem;
  align-self: center;
  justify-self: center;
  @include tablet {
   padding: 1rem;
  }
  &-logo { 
    width: 100%;
    & img { 
       width: 100%;
       height: auto;
    }
  }
}

.layer-photo {
  background: rgb(237,247,254); /* Old browsers */
  background: -moz-linear-gradient(-45deg,  rgba(237,247,254,1) 10%, rgba(218,238,253,1) 50%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg,  rgba(237,247,254,1) 10%,rgba(218,238,253,1) 50%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg,  rgba(237,247,254,1) 10%,rgba(218,238,253,1) 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#edf7fe', endColorstr='#daeefd',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
		@include size(100%);	
		@include absolute(left 0 bottom 15rem);
		z-index: 0;
		&-pic {
		  opacity: .1;
		  mix-blend-mode: luminosity;
   			@include size(100%);	
   			@include absolute(left 0 bottom 0);
   			object-fit: contain;
   			overflow: hidden;	
      transition: $slow;
    @include tablet {
    }
  }
 	&-img {
 	  object-fit: contain;
    object-position: bottom;
 			@include size(100%);	
    transition: $veryslow;
    display: block;
    filter: grayscale(100%);
    @include tablet {
   			object-fit: contain;
    }
		}		
}
